@font-face {
  font-family: 'Patched';
  src: url('/fonts/Patched-Medium.otf')
}

html {
  padding: 0;
  margin: 0;
  background-color: rgba(217, 217, 217, 0.20);
  color-scheme: dark;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  color: white;
  background: #262F36;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h6 {
  font-size: 1.50vmax;
}

@media (min-width: 600px) {
  h2 {
    font-size: 2.0vmax;
  }
}
